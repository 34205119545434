import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { HcpPage, SEO } from "components";
import {
  HCPSavingsCallout,
  BecomePrescriberCallout,
  HCPAveedSignup,
} from "components/shared/callouts";

import imgShots from "images/5-shots.svg";
import imgJets from "images/fighter-jets.png";

import "./index.scss";

const pageReferences = [
  `
  US Food and Drug Administration. Approved drug products with therapeutic equivalence evaluations. 42nd ed. https://www.fda.gov/media/71474/download. Accessed October 12, 2022.
  `,
  `
  Mulhall JP, Trost LW, Brannigan RE. Evaluation and management of testosterone deficiency: AUA Guideline. American Urological Association website. https://www.auanet.org/guidelines-and-quality/guidelines/testosterone-deficiency-guideline.
  Accessed October 12, 2022.
  `,
  `
  AVEED<sup>&reg;</sup> (Prescribing Information). Malvern, PA: Endo Pharmaceuticals Inc.
  `,
];

const pageDescription = "Learn about AVEED® (testosterone undecanoate) injection CIII, including Safety and Boxed Warning, benefits and risks, and full Prescribing Information. See Safety & PI, including Boxed Warning.";

const IndexPage = () => {
  return (
    <HcpPage pageClassName="homepage" references={pageReferences}>
      <SEO pageTitle="HCP Home" pageDescription={pageDescription} />
      <div className="dfa-container  gutter-left gutter-right">
        <div className="hero">
          <img src={imgJets} alt="Fighter Jets" className="fighter-jets" />
          <p className="top-white-text">
            AVEED<sup>&reg;</sup> &mdash; the only FDA-approved{" "}
            <strong className="nobr">LONG-ACTING</strong> testosterone injection
            <sup>1,2</sup>
          </p>
          <div className="inner-hero">
            <p className="condensed-fonts italics">
              ELEVATE AND CONTROL TESTOSTERONE LEVELS WITH
            </p>
            <div className="gradient-green-box">
              <div className="inner-gradient">
                <img src={imgShots} alt="5 shots text" />
              </div>
            </div>
            <div className="transparent-bg">
              <p className="condensed-fonts italics pt-10">
                After the third AVEED<sup>&reg;</sup> injection, 94% of men maintained average
                testosterone levels within normal range; 5.1% of patients had a
                C<sub>avg</sub> &lt;300 ng/dL, and 0.9% of patients had a C
                <sub>avg</sub> &gt;1000 ng/dL.<sup>3</sup>
                <span className="super-asterisk">*</span>
              </p>
              <p className="hero-note">
                *Normal range was defined as 300-1000 ng/dL.
              </p>
            </div>
            <div className="btn-block">
              <a href="/hcp/clinical-data" className="aveed-button green">
                Review the Data
              </a>
            </div>
          </div>
        </div>
        <Row className="callout-blocks-container">
          <Col xs={12} md={4}>
            <HCPSavingsCallout />
          </Col>
          <Col xs={12} md={4}>
            <BecomePrescriberCallout />
          </Col>
          <Col xs={12} md={4}>
            <HCPAveedSignup />
          </Col>
          <Col xs={12} className="pt-10">
            <p className="footnote">
              <sup>&dagger;</sup>Risk Evaluation and Mitigation Strategy.
            </p>
          </Col>
        </Row>
      </div>
    </HcpPage>
  );
};

export default IndexPage;
